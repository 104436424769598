import { ApolloManager } from 'src/helpers/ApolloManager';
import addPuzzleEditorLog from './addPuzzleEditorLog.graphql';
import updatePuzzleEditorLog from './updatePuzzleEditorLog.graphql';

interface PuzzleEditorLogData {
	id?: string;
	dateTimeChanged: string;
	userId: string;
	puzzleId?: string;
	previousCode?: string;
	newCode?: string;
}

interface ResultData {
	createPuzzleEditorLog: {
		id: string;
		dateTimeChanged: string;
		userId: string;
		puzzleId?: string;
		previousCode?: string;
		newCode?: string;
	};
}

interface Result {
	success: boolean;
	error?: string;
	data?: ResultData;
}

class PuzzleEditorLogImplementation {
	public readonly addPuzzleEditorLog = async (
		puzzleEditorLogData: PuzzleEditorLogData
	): Promise<Result> => {
		let success: boolean = false;
		let data = undefined;
		try {
			const result = await ApolloManager.client.mutate<ResultData>({
				mutation: addPuzzleEditorLog,
				variables: puzzleEditorLogData,
			});
			success = result ? true : false;
			if (result.data) data = result.data;
		} catch (error) {
			console.error(`Puzzle Editor Log creation failed.`);
		}
		return {
			success: success,
			error: !success ? `Failed to Create Puzzle Editor Log.` : undefined,
			data: data,
		};
	};

	public readonly addPuzzleEditorLog2 = async (
		puzzleEditorLogData: PuzzleEditorLogData
	): Promise<Result> => {
		let data = undefined;
		try {
			const result = await ApolloManager.client.mutate<ResultData>({
				mutation: addPuzzleEditorLog,
				variables: puzzleEditorLogData,
			});
			if (!result || result.data == undefined) {
				throw new Error(
					'Failed to Create Puzzle Editor Log.  The length may be too long. Copy individual questions through Keystone instead.'
				);
			}
			data = result.data;
		} catch (error) {
			throw error;
		}
		// TODO We can refactor Result, as 'success' and 'error' are no longer required.
		return {
			success: true,
			error: undefined,
			data: data,
		};
	};

	public readonly updatePuzzleEditorLog = async (
		puzzleEditorLogData: PuzzleEditorLogData
	): Promise<Result> => {
		let success: boolean = false;
		let data = undefined;
		try {
			const result = await ApolloManager.client.mutate<ResultData>({
				mutation: updatePuzzleEditorLog,
				variables: puzzleEditorLogData,
			});
			success = result ? true : false;
			if (result.data) data = result.data;
		} catch (error) {
			console.error(`Puzzle Editor Log update failed.`);
		}
		return {
			success: success,
			error: !success ? `Failed to Update Puzzle Editor Log.` : undefined,
			data: data,
		};
	};
}

export const PuzzleEditorLogManager = new PuzzleEditorLogImplementation();

import React from 'react';
import { navigate } from 'gatsby';
import { Layout } from 'src/components/Layout';
import styles from './styles.module.css';
import { Spinner } from 'src/components/Spinner';
import { UserManager } from 'src/helpers/UserManager';
import { Button, Input, message } from 'antd';
import { Tabs, Tab } from 'src/components/Tabs/Tabs';
import { ArrowButton } from 'src/components/ArrowButton/component';
import { Dropdown } from 'src/components/Dropdown/component';
import { Checkbox } from 'src/components/Checkbox/component';
import { Puzzle } from 'src/model/PuzzleData';
import { Question, PuzzleManager } from 'src/helpers/PuzzleManager';
import { PuzzleEditorLogManager } from 'src/helpers/PuzzleEditorLogManager';
import {
	Element,
	GenericPuzzle,
	getListOfUniqueElementNames,
	getListOfUniqueElementTypes,
	getElementFromName,
	getCustomFieldsFromElementType,
} from 'src/components/GenericPuzzle';

interface Props {}

interface State {
	loading: boolean;
	userId: number;
	databasePuzzles: Puzzle[];
	selectedDatabasePuzzle: number;
	currentMainTab: number;
	puzzle: Puzzle;
	questions: Question[];
	currentQuestionIndex: number;
	importStringPuzzle: string;
	exportStringPuzzle: string;

	puzzleType: string;
	elements: Element[];
	layoutData: string[][];
	nextKey: number;
	currentTab: number;
	multiSelectEnabled: boolean;
	currentIDs: number[];
	currentSelection: number[];
	currentName?: string;
	currentType?: string;
	currentValue?: string;
	currentCustomField: string;
	exportString: string;
	importString: string;
	itemGroupImportString: string;
	itemGroupExportString: string;
	selectedItemToAdd: string;
	genericPuzzle: GenericPuzzle;
	moveToID?: number;
	previouslyImportedCode: string;
}

export default class PuzzleEditor extends React.Component<Props, State> {
	state: State = {
		loading: true,
		userId: -1,
		databasePuzzles: [],
		selectedDatabasePuzzle: -1,
		currentMainTab: 1,
		puzzle: {
			id: -1,
			title: 'Untitled Puzzle',
			titleImage: 'brain',
			expectedDurationMinutes: -1,
			descriptionLine1: '',
			descriptionLine2: '',
			archived: false,
			puzzleType: { id: '-1', key: 'GenericTest', description: '' },
		},
		questions: [],
		currentQuestionIndex: -1,
		importStringPuzzle: '',
		exportStringPuzzle: '"questions":[]',

		puzzleType: 'Auto',
		elements: [],
		layoutData: [],
		nextKey: 0,
		currentTab: 1,
		multiSelectEnabled: false,
		currentIDs: [],
		currentSelection: [],
		currentCustomField: '',
		exportString: '[{}]',
		importString: '',
		itemGroupExportString: '[{}]',
		itemGroupImportString: '',
		selectedItemToAdd: '',
		genericPuzzle: new GenericPuzzle({
			currQuestionNum: -1,
			currQuestionSpec: null,
			questionTotal: null,
			nextQuestion: null,
		}),
		previouslyImportedCode: '',
	};

	async componentDidMount() {
		let { loading } = this.state;
		const user = await UserManager.getAuthenticatedUser();
		if (!user || user.guest) {
			// no user found so send them to sign in
			navigate('/sign-in?url=puzzle-editor');
			return;
		}
		const userPermissions = await UserManager.getAuthenticatedUserPermissions();
		if (!userPermissions || !userPermissions.isAdmin || !userPermissions.isSuperUser) {
			// user isn't authorised to use puzzle editor, so return them to home page
			navigate('/');
			return;
		}
		const databasePuzzles = await PuzzleManager.getAllPuzzles();
		databasePuzzles.sort((a, b) =>
			a.title == b.title ? (a.id < b.id ? -1 : 1) : a.title < b.title ? -1 : 1
		);
		loading = false;
		this.setState({ loading, userId: user.id || -1, databasePuzzles });
	}

	addQuestion() {
		const { questions } = this.state;
		let maxSequence = -1;
		questions.forEach((question) => {
			if (question.sequenceNumber > maxSequence) maxSequence = question.sequenceNumber;
		});
		questions.push({
			id: -1,
			sequenceNumber: maxSequence + 1,
			specification: `{"puzzleType":"Auto", "elements":[]}`,
		});
		const currentQuestionIndex = questions.length - 1;
		this.setState({ questions, currentQuestionIndex });
		this.updatePuzzleExportString(this.state.puzzle, questions);
		this.importQuestion(currentQuestionIndex);
	}

	duplicateQuestion() {
		let { questions, currentQuestionIndex } = this.state;
		let maxSequence = 0;
		questions.forEach((question) => {
			if (question.sequenceNumber > maxSequence) maxSequence = question.sequenceNumber;
		});
		let newQuestion = { ...questions[currentQuestionIndex] };
		newQuestion.sequenceNumber = maxSequence + 1;
		newQuestion.id = -1;
		questions.push(newQuestion);
		currentQuestionIndex = questions.length - 1;
		this.setState({ questions, currentQuestionIndex });
		this.updatePuzzleExportString(this.state.puzzle, questions);
		this.importQuestion(currentQuestionIndex);
	}

	deleteQuestion() {
		let { questions, currentQuestionIndex } = this.state;
		if (questions.length > 1) questions.splice(currentQuestionIndex, 1);
		else questions = [];
		currentQuestionIndex = -1;
		this.setState({ questions, currentQuestionIndex });
		this.updatePuzzleExportString(this.state.puzzle, questions);
	}

	copyToClipboard(item: string) {
		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData?.setData('text/plain', item);
			e.preventDefault();
			document.removeEventListener('copy', () => {});
		});
		document.execCommand('copy');
	}

	importFromString(s: string) {
		let { puzzle, questions } = this.state;
		const data = JSON.parse(s);
		if (data['puzzle']) {
			puzzle = data['puzzle'];
		}
		if (data['questions']) {
			questions = data['questions'];
		}
		this.setState({ puzzle, questions, currentQuestionIndex: -1 });
	}

	getPuzzleExportString(puzzle: Puzzle, questions: Question[]) {
		return `{"puzzle":${JSON.stringify(puzzle)}, "questions":${JSON.stringify(questions)}}`;
	}

	updatePuzzleExportString(puzzle: Puzzle, questions: Question[]) {
		this.setState({
			exportStringPuzzle: this.getPuzzleExportString(puzzle, questions),
		});
	}

	getNextKey = () => {
		this.state.nextKey += 1;
		return String(this.state.nextKey - 1);
	};

	addData = (type: string, value: string, customFields: string) => {
		this.state.layoutData.push([type, value, customFields]);
	};

	addSelectedItem = () => {
		const { selectedItemToAdd } = this.state;
		let element = getElementFromName(selectedItemToAdd);
		if (element) {
			const { elements } = this.state;
			let newElement: Element = { ...element };
			elements.push(newElement);
			if (element.type == 'Container_Open') {
				const container_close = getElementFromName('Container Close (Any)');
				if (container_close) {
					elements.push(container_close);
				} else {
					console.warn(`Warning: unable to find data for container_close`);
				}
			}
			this.setState({ elements: elements });
			this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
			this.selectElementToEdit(elements.length - 1);
		} else {
			console.warn(`Warning: unable to find data for ${selectedItemToAdd}`);
		}
	};

	duplicateSelectedItems = () => {
		const { elements, currentIDs } = this.state;
		let containerDepth = 0;
		currentIDs.forEach((currentID) => {
			let newElement: Element = { ...elements[currentID] };
			if (newElement.type == 'Container_Open') {
				containerDepth++;
				elements.push(newElement);
			} else if (newElement.type == 'Container_Close') {
				if (containerDepth > 0) {
					containerDepth--;
					elements.push(newElement);
				}
			} else {
				elements.push(newElement);
			}
		});
		while (containerDepth > 0) {
			const container_close = getElementFromName('Container Close (Any)');
			if (container_close) {
				elements.push(container_close);
			}
			containerDepth--;
		}
		this.setState({ elements: elements });
		this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
	};

	addSelectedGroupItem = () => {
		let { elements, itemGroupImportString } = this.state;
		const data = JSON.parse(itemGroupImportString);
		for (let index in data) {
			const element = data[index];
			elements.push(element);
		}
		this.setState({ elements });
		this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
	};

	getElementsAsList: any = () => {
		const { elements } = this.state;
		let result: JSX.Element[] = [];
		Object.entries(elements)
			.map(([k, v]) => v)
			.map((layoutData, v) =>
				result.push(
					<div key={this.getNextKey()} className={styles.text}>{`${layoutData['name'] || ''}`}</div>
				)
			);
		return (
			<table key={this.getNextKey()} className={styles.table}>
				<tbody>{this.wrapDataInTable(result)}</tbody>
			</table>
		);
	};

	toggleMultiSelect() {
		const { multiSelectEnabled, currentIDs } = this.state;
		if (!multiSelectEnabled) {
			this.setState({ multiSelectEnabled: true });
		} else {
			this.setState({ multiSelectEnabled: false, currentIDs: [currentIDs[0]] });
		}
	}

	deleteSelectedElements() {
		let { currentIDs, elements } = this.state;
		// Add paired elements
		currentIDs.forEach((currentID) => {
			if (elements[currentID].type.toLowerCase() == 'container_open') {
				const pairedElement = this.findLinkedContainerClose(currentID);
				if (pairedElement != -1 && !currentIDs.includes(pairedElement))
					currentIDs.push(pairedElement);
			}
			if (elements[currentID].type.toLowerCase() == 'container_close') {
				const pairedElement = this.findLinkedContainerOpen(currentID);
				if (pairedElement != -1 && !currentIDs.includes(pairedElement))
					currentIDs.push(pairedElement);
			}
		});
		// Delete elements in reverse order so they don't affect each other
		currentIDs.sort((a, b) => b - a);
		currentIDs.map((currentID) => {
			if (currentID < 0 || currentID > elements.length - 1) {
				// Cannot remove element outside of array bounds
			} else {
				elements.copyWithin(currentID, currentID + 1);
				elements.pop();
			}
		});
		currentIDs = [];
		this.setState({ currentIDs: currentIDs });
		this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
	}

	findLinkedContainerClose(index: number) {
		const { elements } = this.state;
		let count = 0;
		let result = -1;
		elements.forEach((element, i: number) => {
			if (i > index && result === -1) {
				if (element.type.toLowerCase() == 'container_close') {
					if (count <= 0) {
						result = i;
					} else {
						count -= 1;
					}
				} else if (element.type.toLowerCase() == 'container_open') {
					count += 1;
				}
			}
		});
		return result;
	}

	findLinkedContainerOpen(index: number) {
		const { elements } = this.state;
		let count = 0;
		let result = -1;
		elements.forEach((element, i: number) => {
			if (i < index && result === -1) {
				if (element.type.toLowerCase() == 'container_open') {
					if (count <= 0) {
						result = i;
					} else {
						count -= 1;
					}
				} else if (element.type.toLowerCase() == 'container_close') {
					count += 1;
				}
			}
		});
		return result;
	}

	moveAllSelectedElementsUp() {
		const { currentIDs } = this.state;
		if (currentIDs.length >= 1) {
			const firstPosition = 0;
			currentIDs.sort((a, b) => a - b);
			let valid = true;
			currentIDs.forEach((currentID) => {
				if (currentID == firstPosition) valid = false;
			});
			if (valid) {
				currentIDs.forEach((_v, index) => {
					this.moveElementToPosition(currentIDs[index], currentIDs[index] - 1);
					currentIDs[index] -= 1;
				});
				this.setState({ currentIDs: currentIDs });
			}
		}
	}

	moveAllSelectedElementsDown() {
		const { currentIDs, elements } = this.state;
		if (currentIDs.length >= 1) {
			const lastPosition = elements.length - 1;
			currentIDs.sort((a, b) => b - a);
			let valid = true;
			currentIDs.forEach((currentID) => {
				if (currentID == lastPosition) valid = false;
			});
			if (valid) {
				currentIDs.forEach((_v, index) => {
					this.moveElementToPosition(currentIDs[index], currentIDs[index] + 1);
					currentIDs[index] += 1;
				});
				currentIDs.sort((a, b) => a - b);
				this.setState({ currentIDs: currentIDs });
			}
		}
	}

	moveAllSelectedElementsToTop() {
		this.moveAllSelectedElementsToPosition(0);
	}

	moveAllSelectedElementsToBottom() {
		this.moveAllSelectedElementsToPosition(this.state.elements.length - 1);
	}

	moveAllSelectedElementsToPosition(position: number) {
		const { currentIDs, elements } = this.state;
		if (position < 0 || position >= elements.length) {
			// Invalid position, so cancel operation
			return;
		}
		// There are 2 valid positions: above all elements or below all elements. All other positions are ambiguous, so ignore them
		let isAbove = true;
		let isBelow = true;
		currentIDs.forEach((currentID) => {
			if (currentID <= position) isAbove = false;
			if (currentID >= position) isBelow = false;
		});
		if (isAbove && !isBelow) {
			currentIDs.sort((a, b) => a - b);
			const distance = currentIDs[0] - position;
			currentIDs.forEach((_v, index) => {
				// Move all elements up a set distance based on the top-most element being moved to the top
				this.moveElementToPosition(currentIDs[index], currentIDs[index] - distance);
				currentIDs[index] -= distance;
			});
			this.setState({ currentIDs: currentIDs });
		} else if (!isAbove && isBelow) {
			currentIDs.sort((a, b) => b - a);
			const distance = position - currentIDs[0];
			currentIDs.forEach((_v, index) => {
				// Move all elements up a set distance based on the top-most element being moved to the top
				this.moveElementToPosition(currentIDs[index], currentIDs[index] + distance);
				currentIDs[index] += distance;
			});
			currentIDs.sort((a, b) => a - b);
			this.setState({ currentIDs: currentIDs });
		}
	}

	moveElementToPosition(index: number, position: number) {
		const { elements } = this.state;
		if (position < 0 || position > elements.length - 1) {
			// Cannot move to an invalid position
		} else if (index < 0 || index > elements.length - 1) {
			// Cannot move invalid element
		} else {
			const element = elements[index];
			// Remove element from previous position
			elements.splice(index, 1);
			// Move element to target position
			elements.splice(position, 0, element);
			this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
			return true;
		}
		return false;
	}

	scrollTopToPosition(elementID: number) {
		// Window is not accessible during server side deployment, so it must be checked prior to usage
		const currentItems = typeof window !== 'undefined' ? document.getElementById('items') : null;
		const rowHeight =
			currentItems?.firstElementChild?.firstElementChild?.firstElementChild?.scrollHeight;
		//Scrolls so selected ID is at the top. Determined by first selected ID * row height
		currentItems?.scrollTo({
			top: Number(elementID) * Number(rowHeight),
			behavior: 'smooth',
		});
	}

	swapFocused() {
		// TODO: find a better name for this function
		const { currentSelection } = this.state;
		let { currentIDs } = this.state;
		if (currentSelection.length == 2) {
			let currentID = currentSelection[1];
			let targetID = currentSelection[0];
			if (currentIDs.includes(currentSelection[0])) {
				// This is the element to remove and replace with the new element
				currentID = currentSelection[0];
				targetID = currentSelection[1];
			}
			currentIDs.length == 1 ? (currentIDs = []) : currentIDs.splice(currentID, 1);
			this.selectElementToEdit(targetID);
			this.scrollTopToPosition(targetID);
		}
		this.updateItemGroupExportString(currentIDs);
	}

	isElementSelected(elementID: number) {
		return this.state.currentIDs.includes(elementID);
	}

	isElementHighlighted(elementID: number) {
		return this.state.currentSelection.includes(elementID);
	}

	selectElementToEdit(elementID: number) {
		// This function currently depends on caller to apply out of bounds checks
		const { multiSelectEnabled, elements } = this.state;
		let { currentIDs, currentSelection } = this.state;
		let found = false;
		currentSelection = [];
		for (let index = 0; index < currentIDs.length; index++) {
			if (currentIDs[index] === elementID) {
				found = true;
				currentIDs.length == 1 ? (currentIDs = []) : currentIDs.splice(index, 1);
				index--;
			}
		}
		if (!found) {
			!multiSelectEnabled ? (currentIDs = [elementID]) : currentIDs.push(elementID);
			// Only add to current selection if the item isn't being removed from the list
			currentSelection.push(elementID);
			if (elements[elementID].type.toLowerCase() == 'container_open') {
				const pairedElement = this.findLinkedContainerClose(elementID);
				if (pairedElement != -1) currentSelection.push(pairedElement);
			}
			if (elements[elementID].type.toLowerCase() == 'container_close') {
				const pairedElement = this.findLinkedContainerOpen(elementID);
				if (pairedElement != -1) currentSelection.push(pairedElement);
			}
		}
		if (currentIDs.length === 1) {
			const element = elements[currentIDs[0]];
			this.setState({
				currentIDs: currentIDs,
				currentName: element.name,
				currentType: element.type,
				currentValue: element.value,
				moveToID: currentIDs[0],
			});
		} else {
			currentIDs.sort((a, b) => a - b);
			this.setState({ currentIDs: currentIDs });
		}
		this.setState({ currentSelection, currentCustomField: '' });
		this.updateItemGroupExportString(currentIDs);
	}

	fillSelectedElements() {
		let { currentIDs } = this.state;
		if (currentIDs.length > 1) {
			let min = currentIDs[0];
			let max = currentIDs[0];
			currentIDs.map((currentID) => {
				if (currentID < min) min = currentID;
				if (currentID > max) max = currentID;
			});
			currentIDs = [...Array(max - min + 1).keys()].map((v) => {
				return v + min;
			});
			this.setState({ currentIDs: currentIDs });
		}
	}

	clearSelectedElements() {
		this.setState({ currentIDs: [] });
	}

	setCurrentName(currentName: string) {
		const { elements, currentIDs } = this.state;
		if (currentIDs.length !== 1 || currentIDs[0] === -1) {
			// Cannot change type for invalid id
			return false;
		}
		elements[currentIDs[0]].name = currentName || '';
		this.setState({ currentName: currentName });
		this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
		return true;
	}

	setCurrentType(currentType: string) {
		const { elements, currentIDs } = this.state;
		if (currentIDs.length !== 1 || currentIDs[0] === -1) {
			// Cannot change type for invalid id
			return false;
		}
		elements[currentIDs[0]].type = currentType || '';
		this.setState({ currentType: currentType });
		this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
		return true;
	}

	setCurrentValue(currentValue: string) {
		const { elements, currentIDs } = this.state;
		if (currentIDs.length !== 1 || currentIDs[0] === -1) {
			// Cannot change value for invalid id
			return false;
		}
		elements[currentIDs[0]].value = currentValue;
		this.setState({ currentValue: currentValue });
		this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
		return true;
	}

	setCurrentCustomValue(value: string) {
		const { elements, currentIDs, currentCustomField } = this.state;
		if (currentIDs.length !== 1 || currentIDs[0] === -1) {
			// Cannot change value for invalid id
			return false;
		}
		elements[currentIDs[0]].customFields[currentCustomField] = value;
		this.setState({ elements: elements });
		this.updateQuestionExportStringForQuestion(elements, this.state.currentQuestionIndex);
		return true;
	}

	wrapDataInTable = (data: JSX.Element[]) => {
		let result: JSX.Element[] = [];
		data.forEach((element: JSX.Element, index: number) => {
			result.push(
				<tr
					className={
						this.isElementHighlighted(index)
							? styles.highlighted
							: this.isElementSelected(index)
							? styles.selected
							: undefined
					}
					key={this.getNextKey()}
				>
					<td>{index}</td>
					<td>{element}</td>
					<td>
						<Button
							onClick={() => {
								this.selectElementToEdit(index);
							}}
						>
							E
						</Button>
					</td>
				</tr>
			);
		});
		return result;
	};

	importQuestion(question: number) {
		const { questions } = this.state;
		if (question != -1) {
			this.importQuestionFromString(question, questions[question].specification);
		}
	}

	importQuestionFromString(question: number, s: string) {
		let { puzzleType, elements } = this.state;
		const data = JSON.parse(s);
		if (data['puzzleType']) {
			puzzleType = data['puzzleType'];
		}
		if (data['elements']) {
			elements = data['elements'];
		}
		this.setState({ puzzleType, elements });
		this.updateDefaultNames(elements);
		this.updateQuestionExportStringForQuestion(elements, question);
	}

	updateDefaultNames(elements: Element[]) {
		elements.forEach((element) => {
			if (!element.name) {
				element.name = `${element.type}`;
			}
		});
		this.setState({ elements });
	}

	updateQuestionExportStringForQuestion(elements: Element[], question: number) {
		let { questions } = this.state;
		if (question != -1) {
			const exportString = `{"puzzleType":"${this.state.puzzleType}","elements":${JSON.stringify(
				elements
			)}}`;
			questions[question].specification = exportString;
			this.setState({ exportString, questions });
			this.updatePuzzleExportString(this.state.puzzle, questions);
		}
	}

	updateItemGroupExportString(currentIDs: number[]) {
		const { elements } = this.state;
		let currentElements = elements.filter((_v, i) => currentIDs.includes(i));
		let itemGroupExportString = JSON.stringify(currentElements);
		this.setState({ itemGroupExportString: itemGroupExportString });
	}

	async loadPuzzle(puzzleID: number) {
		let { databasePuzzles } = this.state;
		const databaseQuestions = await PuzzleManager.getAllQuestionsForPuzzle(puzzleID);
		let questions = databaseQuestions.map((question) => {
			return { ...question };
		});
		questions.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
		let puzzle = null;
		databasePuzzles.map((databasePuzzle) => {
			if (databasePuzzle.id == puzzleID) puzzle = { ...databasePuzzle };
		});
		if (puzzle != null) this.setState({ puzzle, questions, currentQuestionIndex: -1 });
	}

	async savePuzzleToDatabase(puzzleID: number) {
		const { userId } = this.state;
		if (userId == -1) {
			message.error(`Invalid user. Unable to save puzzle to database`);
			return;
		}
		if (puzzleID == -1) {
			await this.savePuzzleToDatabaseAsNewPuzzle();
		} else {
			await this.savePuzzleToDatabaseAsExistingPuzzle(puzzleID);
		}
		const databasePuzzles = await PuzzleManager.getAllPuzzles();
		this.setState({ databasePuzzles });
	}

	async savePuzzleToDatabaseAsNewPuzzle() {
		const { userId, exportStringPuzzle, puzzle, questions } = this.state;
		let puzzleEditorLogData: any = {
			dateTimeChanged: new Date().toISOString(),
			userId: String(userId),
			puzzleId: '-1',
			previousCode: '',
			newCode: exportStringPuzzle,
		};
		const result = await PuzzleEditorLogManager.addPuzzleEditorLog(puzzleEditorLogData);
		if (!result.success) {
			message.error(
				'Unable to save puzzle. The length may be too long. Copy individual questions through Keystone instead.'
			);
		} else {
			const logId = result.data?.createPuzzleEditorLog.id;
			const PuzzleTypeIds = await PuzzleManager.getAllPuzzleTypesByKey('GenericTest');
			if (!PuzzleTypeIds.allPuzzleTypes || PuzzleTypeIds.allPuzzleTypes.length < 1) {
				message.error('Unable to save puzzle as a GenericTest PuzzleType could not be found');
			} else {
				const puzzleVariables: any = { ...puzzle };
				puzzleVariables.puzzleType = PuzzleTypeIds.allPuzzleTypes[0].id;
				puzzleVariables.expectedDurationMinutes = Number(puzzleVariables.expectedDurationMinutes);
				var createdPuzzle = await PuzzleManager.CreatePuzzle(puzzleVariables);
				if (!createdPuzzle.puzzle) {
					message.error('Unknown error creating puzzle. Aborting');
				} else {
					const newPuzzleID = createdPuzzle.puzzle.id;
					puzzleEditorLogData = {
						id: logId,
						puzzleId: newPuzzleID,
					};
					const result = await PuzzleEditorLogManager.addPuzzleEditorLog(puzzleEditorLogData);
					if (!result.success) {
						message.warning(
							'Unable to update puzzle editor log with puzzle id. Attempting to continue.'
						);
					}
					questions.map(async (question) => {
						var questionVariables: any = { ...question };
						questionVariables.puzzle = String(newPuzzleID);
						await PuzzleManager.CreateQuestion(questionVariables);
					});
				}
			}
		}
	}

	async savePuzzleToDatabaseAsNewPuzzle2() {
		const { userId, exportStringPuzzle, puzzle, questions } = this.state;

		try {
			await this.checkGenericTestPuzzleTypes();

			let puzzleEditorLogData: any = {
				dateTimeChanged: new Date().toISOString(),
				userId: String(userId),
				puzzleId: '-1',
				previousCode: '',
				newCode: exportStringPuzzle,
			};

			const result = await PuzzleEditorLogManager.addPuzzleEditorLog2(puzzleEditorLogData);
			const logId = result.data?.createPuzzleEditorLog.id;

			const puzzleVariables: any = { ...puzzle };
			puzzleVariables.puzzleType = puzzle.puzzleType.id;
			puzzleVariables.expectedDurationMinutes = Number(puzzleVariables.expectedDurationMinutes);
			const createdPuzzle = await PuzzleManager.CreatePuzzle2(puzzleVariables);

			const newPuzzleID = createdPuzzle.puzzle.id;
			puzzleEditorLogData = {
				id: logId,
				puzzleId: newPuzzleID,
			};
			await PuzzleEditorLogManager.addPuzzleEditorLog2(puzzleEditorLogData);

			questions.map(async (question) => {
				var questionVariables: any = { ...question };
				questionVariables.puzzle = String(newPuzzleID);
				await PuzzleManager.CreateQuestion(questionVariables);
			});
		} catch (e: any) {
			message.error(`Unable to save puzzle. ${e.message}`);
		}
	}

	async savePuzzleToDatabaseAsNewPuzzle3() {
		try {
			await this.checkGenericTestPuzzleTypes();
			this.savePuzzleToDatabaseAsNewPuzzle3a();
		} catch (e: any) {
			message.error(`Unable to save puzzle. ${e.message}`);
		}
	}

	async savePuzzleToDatabaseAsNewPuzzle3a() {
		const { userId, exportStringPuzzle } = this.state;

		let puzzleEditorLogData: any = {
			dateTimeChanged: new Date().toISOString(),
			userId: String(userId),
			puzzleId: '-1',
			previousCode: '',
			newCode: exportStringPuzzle,
		};

		this.savePuzzleToDatabaseAsNewPuzzle3b(puzzleEditorLogData);
	}

	async savePuzzleToDatabaseAsNewPuzzle3b(puzzleEditorLogData: any) {
		const { puzzle, questions } = this.state;

		const result = await PuzzleEditorLogManager.addPuzzleEditorLog2(puzzleEditorLogData);
		const logId = result.data?.createPuzzleEditorLog.id;

		const puzzleVariables: any = { ...puzzle };
		puzzleVariables.puzzleType = puzzle.puzzleType.id;
		puzzleVariables.expectedDurationMinutes = Number(puzzleVariables.expectedDurationMinutes);
		const createdPuzzle = await PuzzleManager.CreatePuzzle2(puzzleVariables);

		const newPuzzleID = createdPuzzle.puzzle.id;
		puzzleEditorLogData = {
			id: logId,
			puzzleId: newPuzzleID,
		};
		await PuzzleEditorLogManager.addPuzzleEditorLog2(puzzleEditorLogData);

		this.savePuzzleToDatabaseAsNewPuzzle3c(questions, newPuzzleID);
	}
	async savePuzzleToDatabaseAsNewPuzzle3c(questions: Question[], newPuzzleID: number) {
		questions.map(async (question) => {
			var questionVariables: any = { ...question };
			questionVariables.puzzle = String(newPuzzleID);
			await PuzzleManager.CreateQuestion(questionVariables);
		});
	}

	async checkGenericTestPuzzleTypes() {
		const PuzzleTypeIds = await PuzzleManager.getAllPuzzleTypesByKey('GenericTest');
		if (!PuzzleTypeIds.allPuzzleTypes || PuzzleTypeIds.allPuzzleTypes.length < 1) {
			throw new Error('A Generic Test Puzzle Type could not be found.');
		}
	}

	async savePuzzleToDatabaseAsExistingPuzzle(puzzleID: number) {
		const { userId, databasePuzzles, exportStringPuzzle, puzzle, questions } = this.state;
		let index = -1;
		databasePuzzles.map((databasePuzzle, i) => {
			if (databasePuzzle.id == puzzleID) index = i;
		});
		if (index == -1)
			message.error(
				`Unable to save puzzle - could not find existing puzzle in database with id ${puzzleID}`
			);
		else {
			const databaseQuestionsQueryResult = await PuzzleManager.getAllQuestionsForPuzzle(puzzleID);
			let databaseQuestions = databaseQuestionsQueryResult.map((question) => {
				return { ...question };
			});
			let existingPuzzle = null;
			databasePuzzles.map((databasePuzzle) => {
				if (databasePuzzle.id == puzzleID) existingPuzzle = { ...databasePuzzle };
			});
			if (existingPuzzle == null) {
				message.error(`Unable to save puzzle - unknown error copying existing puzzle.`);
			} else {
				// Log data created in two parts as code strings are long enough to generate PayloadTooLargeErrors if sent simultaneously
				const previousCode = this.getPuzzleExportString(existingPuzzle, databaseQuestions);
				let puzzleEditorLogData: any = {
					dateTimeChanged: new Date().toISOString(),
					userId: String(userId),
					puzzleId: String(puzzleID),
					previousCode: previousCode,
				};
				let result = await PuzzleEditorLogManager.addPuzzleEditorLog(puzzleEditorLogData);
				if (!result.success) {
					message.error(
						'Unable to save puzzle. The length may be too long. Copy individual questions through Keystone instead.'
					);
				} else {
					const id = result.data?.createPuzzleEditorLog.id;
					puzzleEditorLogData = {
						id: id,
						newCode: exportStringPuzzle,
					};
					result = await PuzzleEditorLogManager.updatePuzzleEditorLog(puzzleEditorLogData);
					if (!result.success) {
						message.error(
							'Unable to save puzzle. The length may be too long. Copy individual questions through Keystone instead.'
						);
					} else {
						let puzzleVariables: any = { ...puzzle };
						puzzleVariables.id = String(puzzleID);
						await PuzzleManager.UpdatePuzzle(puzzleVariables);
						databaseQuestions.map(async (databaseQuestion) => {
							let found = false;
							questions.map(async (question) => {
								if (question.id == databaseQuestion.id) {
									found = true;
									if (JSON.stringify(databaseQuestion) != JSON.stringify(question)) {
										let questionVariables: any = { ...question };
										await PuzzleManager.UpdateQuestion(questionVariables);
									}
								}
							});
							if (!found) {
								if (databaseQuestion.id && databaseQuestion.id != -1) {
									PuzzleManager.DeleteQuestion(databaseQuestion.id);
								}
							}
						});
						questions.map((question) => {
							if (question.id == -1) {
								let questionVariables: any = { ...question };
								questionVariables.puzzle = puzzleID;
								PuzzleManager.CreateQuestion(questionVariables);
							}
						});
					}
				}
			}
		}
	}

	render() {
		const {
			loading,
			databasePuzzles,
			selectedDatabasePuzzle,
			exportStringPuzzle,
			currentName,
			currentType,
			currentValue,
			currentCustomField,
			elements,
			exportString,
		} = this.state;
		let {
			puzzle,
			questions,
			currentQuestionIndex,
			importStringPuzzle,
			multiSelectEnabled,
			currentIDs,
			currentSelection,
			importString,
			itemGroupImportString,
			layoutData,
			moveToID,
		} = this.state;
		const items = getListOfUniqueElementNames();
		const types = getListOfUniqueElementTypes();
		// Window is not accessible during server side deployment, so it must be checked prior to usage
		const currentItems = typeof window !== 'undefined' ? document.getElementById('items') : null;
		const customFields = getCustomFieldsFromElementType(currentType);
		const questionSequenceConflict =
			questions.filter((_v, index) => {
				return (
					questions.filter((question) => {
						return question.sequenceNumber == questions[index].sequenceNumber;
					}).length == 1
				);
			}).length !== questions.length;
		return (
			<Layout>
				<div className={styles.containerMain}>
					{!loading ? (
						<div>
							<div className={styles.header}>
								<div className={styles.bookend} />
								<h1>Puzzle Editor</h1>
								<div className={styles.bookend} />
							</div>
							<hr />
							<div className={styles.centerContainer}>
								<div className={styles.tab}>
									<Tabs
										vertical={false}
										onChange={(tab: any) => {
											this.setState({ currentMainTab: Number(tab) });
										}}
										controller={self}
									>
										<Tab title="DATABASE SAVE AND LOAD" key="1">
											<h2>Load Puzzles From Database</h2>
											<div className={styles.containerHorizontal}>
												<div className={styles.containerWide}>
													<div className={styles.containerVertical}>
														{databasePuzzles.map((puzzle, index) => {
															return puzzle.puzzleType.key == 'GenericTest' ? (
																<Button
																	key={index}
																	onClick={() => {
																		this.setState({
																			selectedDatabasePuzzle:
																				selectedDatabasePuzzle == index ? -1 : index,
																		});
																	}}
																>
																	{puzzle.title}
																</Button>
															) : null;
														})}
													</div>
												</div>
												<div className={styles.containerWide}>
													<div hidden={selectedDatabasePuzzle == -1}>
														{selectedDatabasePuzzle != -1 ? (
															<div className={styles.containerVertical}>
																<div>{databasePuzzles[selectedDatabasePuzzle].title}</div>
																<div>
																	{databasePuzzles[selectedDatabasePuzzle].descriptionLine1}
																</div>
																<div>
																	{databasePuzzles[selectedDatabasePuzzle].descriptionLine2}
																</div>
															</div>
														) : null}
														<Button
															onClick={() => {
																this.loadPuzzle(databasePuzzles[selectedDatabasePuzzle].id);
															}}
														>
															Load and edit
														</Button>
													</div>
												</div>
											</div>
											<h2>Save Current Puzzle To Database</h2>
											<div className={styles.containerHorizontal}>
												<div className={styles.containerWide}>
													<table key={`SavePuzzleTable`} className={styles.table}>
														<tbody>
															<tr>
																<td>ID:</td>
																<td>{puzzle.id}</td>
															</tr>
															<tr>
																<td>Title:</td>
																<td>{puzzle.title}</td>
															</tr>
															<tr>
																<td>Number of questions:</td>
																<td>{questions.length}</td>
															</tr>
															<tr>
																<td>Sequence conflict:</td>
																<td>{String(questionSequenceConflict)}</td>
															</tr>
														</tbody>
													</table>
													<div>Does puzzle already exist?</div>
													<div>Should this overwrite the existing puzzle?</div>
													<div>For each question, does that question already exist?</div>
													<div>
														Should all questions linked to this puzzle be deleted and re-created?
														(probably not)
													</div>
													<div>
														How does a question get deleted if removing it from the list doesn't
														mean it will be removed from the database?
													</div>
												</div>

												{questionSequenceConflict ? (
													<div className={styles.containerWide}>
														<div>Cannot save while there is a question sequence conflict</div>
													</div>
												) : (
													<div className={styles.containerWide}>
														<hr
															hidden={
																questionSequenceConflict ||
																databasePuzzles.filter((databasePuzzle) => {
																	return databasePuzzle.id == puzzle.id;
																}).length <= 0
															}
														/>
														<Button
															hidden={
																questionSequenceConflict ||
																databasePuzzles.filter((databasePuzzle) => {
																	return databasePuzzle.id == puzzle.id;
																}).length <= 0
															}
															onClick={() => {
																this.savePuzzleToDatabase(puzzle.id);
															}}
														>
															Save over existing puzzle in database
														</Button>
														<hr hidden={questionSequenceConflict} />
														<Button
															hidden={questionSequenceConflict}
															onClick={() => {
																this.savePuzzleToDatabase(-1);
															}}
														>
															Save as a new puzzle in database
														</Button>
														<hr hidden={questionSequenceConflict} />
													</div>
												)}
											</div>
										</Tab>

										<Tab title="PUZZLE EDITOR" key="2">
											<div className={styles.containerHorizontal}>
												<div className={styles.containerWide}>
													<div className={styles.containerVertical}>
														<h2>Puzzle Details</h2>
														<table key={`PuzzleTable`} className={styles.table}>
															<tbody>
																<tr>
																	<td>
																		<Button
																			onClick={() => {
																				const puzzle = {
																					id: -1,
																					title: 'Untitled Puzzle',
																					titleImage: 'brain',
																					expectedDurationMinutes: -1,
																					descriptionLine1: '',
																					descriptionLine2: '',
																					archived: false,
																					puzzleType: {
																						id: '-1',
																						key: 'GenericTest',
																						description: '',
																					},
																				};
																				const questions: Question[] = [];
																				this.setState({
																					puzzle,
																					questions,
																					currentQuestionIndex: -1,
																				});
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																		>
																			New Puzzle
																		</Button>
																	</td>
																</tr>
																<tr>
																	<td>Title:</td>
																	<td>
																		<Input
																			onChange={(e) => {
																				puzzle.title = e.target.value;
																				this.setState({ puzzle });
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																			value={puzzle.title}
																		/>
																	</td>
																</tr>
																<tr>
																	<td>Image:</td>
																	<td>
																		<Input
																			onChange={(e) => {
																				puzzle.titleImage = e.target.value;
																				this.setState({ puzzle });
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																			value={puzzle.titleImage}
																		/>
																	</td>
																</tr>
																<tr>
																	<td>Expected Duration (minutes):</td>
																	<td>
																		<Input
																			onChange={(e) => {
																				puzzle.expectedDurationMinutes = Number(e.target.value);
																				this.setState({ puzzle });
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																			value={puzzle.expectedDurationMinutes}
																		/>
																	</td>
																</tr>
																<tr>
																	<td>Description 1:</td>
																	<td>
																		<Input
																			onChange={(e) => {
																				puzzle.descriptionLine1 = e.target.value;
																				this.setState({ puzzle });
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																			value={puzzle.descriptionLine1}
																		/>
																	</td>
																</tr>
																<tr>
																	<td>Description 2:</td>
																	<td>
																		<Input
																			onChange={(e) => {
																				puzzle.descriptionLine2 = e.target.value;
																				this.setState({ puzzle });
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																			value={puzzle.descriptionLine2}
																		/>
																	</td>
																</tr>
																<tr>
																	<td>Archived:</td>
																	<td>
																		<Checkbox
																			caption=""
																			checked={puzzle.archived}
																			onChecked={() => {
																				puzzle.archived = !puzzle.archived;
																				this.setState({ puzzle });
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																		/>
																	</td>
																</tr>
																<tr>
																	<td>Threshold:</td>
																	<td>
																		<Input
																			onChange={(e) => {
																				puzzle.threshold = e.target.value;
																				this.setState({ puzzle });
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																			value={puzzle.threshold}
																		/>
																	</td>
																</tr>
																<tr>
																	<td>High Score Popup Threshold:</td>
																	<td>
																		<Input
																			onChange={(e) => {
																				puzzle.highScorePopupThreshold = e.target.value;
																				this.setState({ puzzle });
																				this.updatePuzzleExportString(puzzle, questions);
																			}}
																			value={puzzle.highScorePopupThreshold}
																		/>
																	</td>
																</tr>
															</tbody>
														</table>
														<div hidden={currentQuestionIndex == -1}>
															<h2>Edit Question</h2>
															<div className={styles.containerHorizontal}>
																Sequence:
																<Input
																	onChange={(e) => {
																		questions[currentQuestionIndex].sequenceNumber = Number(
																			e.target.value
																		);
																		this.setState({ questions });
																		this.updatePuzzleExportString(puzzle, questions);
																	}}
																	value={
																		currentQuestionIndex == -1
																			? ''
																			: questions[currentQuestionIndex].sequenceNumber
																	}
																/>
															</div>
															<div className={styles.containerHorizontal}>
																Specification:
																<Input
																	value={
																		currentQuestionIndex == -1
																			? ''
																			: questions[currentQuestionIndex].specification
																	}
																/>
															</div>
															<div className={styles.containerHorizontal}>
																Correct Answer:
																<Input
																	onChange={(e) => {
																		questions[currentQuestionIndex].correctAnswer = e.target.value;
																		this.setState({ questions });
																		this.updatePuzzleExportString(puzzle, questions);
																	}}
																	value={
																		currentQuestionIndex == -1
																			? ''
																			: questions[currentQuestionIndex].correctAnswer
																	}
																/>
															</div>
															<div className={styles.containerHorizontal}>
																Threshold:
																<Input
																	onChange={(e) => {
																		questions[currentQuestionIndex].threshold = Number(
																			e.target.value
																		);
																		this.setState({ questions });
																		this.updatePuzzleExportString(puzzle, questions);
																	}}
																	value={
																		currentQuestionIndex == -1
																			? ''
																			: questions[currentQuestionIndex].threshold
																	}
																/>
															</div>
															<Button
																onClick={() => {
																	this.deleteQuestion();
																}}
															>
																DELETE QUESTION
															</Button>
														</div>
													</div>
												</div>
												<div className={styles.containerWide}>
													<div className={styles.containerVertical}>
														<h2>Questions</h2>
														<div className={styles.containerHorizontal}>
															<Button
																onClick={() => {
																	this.addQuestion();
																}}
															>
																ADD QUESTION
															</Button>
															<div hidden={currentQuestionIndex == -1}>
																<Button
																	onClick={() => {
																		this.duplicateQuestion();
																	}}
																>
																	DUPLICATE QUESTION
																</Button>
															</div>
														</div>
														<hr />
														<table key={'table'} className={styles.table}>
															<tbody>
																{questions.map((question, index) => {
																	return (
																		<tr key={index}>
																			<td>
																				<Button
																					key={`Question Button ${index}`}
																					onClick={() => {
																						currentQuestionIndex =
																							currentQuestionIndex == index ? -1 : index;
																						this.setState({ currentQuestionIndex });
																						this.importQuestion(currentQuestionIndex);
																					}}
																				>
																					{`Question ${question.sequenceNumber}`}
																				</Button>
																			</td>
																			<td>{question.threshold}</td>
																		</tr>
																	);
																})}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</Tab>

										{currentQuestionIndex == -1 ? null : (
											<Tab title="QUESTION EDITOR" key="3">
												<div className={styles.tab}>
													<Tabs
														vertical={false}
														onChange={(tab: any) => {
															this.setState({ currentTab: Number(tab) });
														}}
														controller={self}
													>
														<Tab title="EDITOR" key="1">
															<div className={styles.row}>
																<div className={styles.container}>
																	<div className={styles.containerWide}>
																		<h2>Add items</h2>
																		ITEM
																		<Dropdown
																			caption={``}
																			options={items}
																			onChange={(selectedItemToAdd) => {
																				this.setState({ selectedItemToAdd });
																			}}
																		></Dropdown>
																		<ArrowButton
																			caption={`Add`}
																			onClick={this.addSelectedItem}
																		></ArrowButton>
																		<div> </div>
																		<div className={styles.containerVerticalNoGrow}>
																			ITEM GROUP
																			<div className={styles.containerHorizontal}>
																				<Input
																					onChange={(e) => {
																						itemGroupImportString = e.target.value;
																						this.setState({ itemGroupImportString });
																					}}
																					value={itemGroupImportString}
																				/>
																				<Button
																					onClick={() => {
																						this.addSelectedGroupItem();
																					}}
																				>
																					IMPORT GROUP ITEM
																				</Button>
																			</div>
																			<div className={styles.containerHorizontal}>
																				<Input
																					readOnly={true}
																					value={this.state.itemGroupExportString}
																				/>
																				<Button
																					onClick={() => {
																						this.copyToClipboard(this.state.itemGroupExportString);
																					}}
																				>
																					EXPORT GROUP ITEM
																				</Button>
																			</div>
																		</div>
																		<h2>Selection Options</h2>
																		<Checkbox
																			caption="Enable multi-select"
																			checked={multiSelectEnabled}
																			onChecked={() => {
																				this.toggleMultiSelect();
																			}}
																		/>
																		<div hidden={!multiSelectEnabled}>
																			<Button
																				onClick={() => {
																					this.fillSelectedElements();
																				}}
																			>
																				Fill selection
																			</Button>
																			<Button
																				onClick={() => {
																					this.clearSelectedElements();
																				}}
																			>
																				Clear selection
																			</Button>
																		</div>
																		<div> </div>
																		<div hidden={currentIDs.length === 0}>
																			<h2>Raw data editor</h2>
																			<div className={styles.sectionHeading}>
																				ID
																				<Button
																					onClick={() => {
																						this.moveAllSelectedElementsToTop();
																					}}
																				>
																					↑↑
																				</Button>
																				<Button
																					onClick={() => {
																						this.moveAllSelectedElementsUp();
																					}}
																				>
																					↑
																				</Button>
																				<Button
																					onClick={() => {
																						this.moveAllSelectedElementsDown();
																					}}
																				>
																					↓
																				</Button>
																				<Button
																					onClick={() => {
																						this.moveAllSelectedElementsToBottom();
																					}}
																				>
																					↓↓
																				</Button>
																			</div>
																			<div className={styles.container}>
																				<div className={styles.containerVertical}>
																					CURRENT:
																					<Input
																						readOnly={true}
																						value={JSON.stringify(currentIDs)}
																					/>
																					<Button
																						onClick={() => {
																							this.deleteSelectedElements();
																						}}
																					>
																						DELETE
																					</Button>
																				</div>
																				<div className={styles.containerVertical}>
																					TARGET:
																					<Input
																						onChange={(e) => {
																							this.setState({
																								moveToID: Number(e.target.value) || 0,
																							});
																						}}
																						value={moveToID}
																					/>
																					<Button
																						onClick={() => {
																							this.moveAllSelectedElementsToPosition(
																								Number(moveToID)
																							);
																						}}
																					>
																						MOVE HERE
																					</Button>
																				</div>
																			</div>
																			<div> </div>
																			<div>
																				<Button
																					onClick={() => this.scrollTopToPosition(currentIDs[0])}
																				>
																					JUMP TO SELECTED
																				</Button>
																			</div>
																			<div> </div>
																			<div>
																				<Button onClick={() => this.duplicateSelectedItems()}>
																					DUPLICATE SELECTED
																				</Button>
																			</div>
																			<div hidden={currentSelection.length !== 2}>
																				<Button
																					onClick={() => {
																						this.swapFocused();
																					}}
																				>
																					SWAP FOCUSED
																				</Button>
																			</div>
																			<div hidden={currentIDs.length !== 1}>
																				<div className={styles.sectionHeading}>Name</div>
																				<Input
																					onChange={(e) => {
																						this.setCurrentName(e.target.value);
																					}}
																					value={currentName}
																				/>
																				<div className={styles.sectionHeading}>Type</div>
																				<Dropdown
																					caption={''}
																					options={types}
																					onChange={(newType) => {
																						this.setCurrentType(newType);
																					}}
																					value={currentType}
																				></Dropdown>
																				<div className={styles.sectionHeading}>Value</div>
																				<Input
																					onChange={(e) => {
																						this.setCurrentValue(e.target.value);
																					}}
																					value={currentValue}
																				/>
																				<div hidden={customFields && customFields.length == 0}>
																					<div className={styles.sectionHeading}>Custom Fields</div>
																					<Dropdown
																						caption={''}
																						options={customFields}
																						onChange={(currentCustomField) =>
																							this.setState({ currentCustomField })
																						}
																						value={''}
																					></Dropdown>
																					<Input
																						onChange={(e) =>
																							this.setCurrentCustomValue(e.target.value)
																						}
																						value={
																							currentCustomField === '' ||
																							currentIDs.length !== 1 ||
																							currentIDs[0] === -1 ||
																							!elements[currentIDs[0]] ||
																							!elements[currentIDs[0]].customFields
																								? ''
																								: elements[currentIDs[0]].customFields[
																										currentCustomField
																								  ]
																						}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className={styles.containerWide}>
																		<h2>Current items</h2>
																		<table key={this.getNextKey()} className={styles.table}>
																			<tbody>
																				<tr>
																					<td>
																						<div
																							key={this.getNextKey()}
																							className={styles.h3}
																						>{`ID`}</div>
																					</td>
																					<td>
																						<div
																							key={this.getNextKey()}
																							className={styles.h3}
																						>{`Element`}</div>
																					</td>
																					<td>
																						<div
																							key={this.getNextKey()}
																							className={styles.h3}
																						>{`Edit`}</div>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																		<div className={styles.containerCurrentItems} id="items">
																			{this.getElementsAsList()}
																		</div>
																	</div>
																</div>
															</div>
														</Tab>

														<Tab title="PREVIEW" key="2">
															<div className={styles.subContainer}>
																{Object.entries(elements)
																	.map(([_k, v]) => v)
																	.map((layoutData, _v) => {
																		this.addData(
																			layoutData['type'],
																			layoutData['value'],
																			JSON.stringify(
																				layoutData['customFields'] ? layoutData['customFields'] : []
																			)
																		);
																	}) &&
																	this.state.genericPuzzle.unmapFromDataUntilTag('', layoutData)}
															</div>
														</Tab>

														<Tab title="IMPORT/EXPORT" key="3">
															<table key={this.getNextKey()} className={styles.table}>
																<tbody>
																	<tr>
																		<td>IMPORT:</td>
																		<td>
																			<Input
																				onChange={(e) => {
																					importString = e.target.value;
																					this.setState({ importString });
																				}}
																				value={importString}
																			/>
																		</td>
																		<td>
																			<Button
																				onClick={() => {
																					this.importQuestionFromString(
																						currentQuestionIndex,
																						importString
																					);
																				}}
																			>
																				IMPORT
																			</Button>
																		</td>
																	</tr>
																	<tr>
																		<td>EXPORT:</td>
																		<td>
																			<Input readOnly={true} value={exportString} />
																		</td>
																		<td>
																			<Button
																				onClick={() => {
																					this.copyToClipboard(exportString);
																				}}
																			>
																				COPY TO CLIPBOARD
																			</Button>
																		</td>
																	</tr>
																</tbody>
															</table>
														</Tab>
													</Tabs>
												</div>
											</Tab>
										)}

										<Tab title="IMPORT/EXPORT" key="4">
											<table key={`ImportExportTable`} className={styles.table}>
												<tbody>
													<tr>
														<td>IMPORT:</td>
														<td>
															<Input
																onChange={(e) => {
																	importStringPuzzle = e.target.value;
																	this.setState({ importStringPuzzle });
																}}
																value={importStringPuzzle}
															/>
														</td>
														<td>
															<Button
																onClick={() => {
																	this.importFromString(importStringPuzzle);
																}}
															>
																IMPORT
															</Button>
														</td>
													</tr>
													<tr>
														<td>EXPORT:</td>
														<td>
															<Input readOnly={true} value={exportStringPuzzle} />
														</td>
														<td>
															<Button
																onClick={() => {
																	this.copyToClipboard(exportStringPuzzle);
																}}
															>
																COPY TO CLIPBOARD
															</Button>
														</td>
													</tr>
												</tbody>
											</table>
										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
